import { useState,useRef,memo } from 'react';
import axios from 'axios'
import styles from './inputNresult.module.css'


function SearchResultDisplayComp({language,langTrans,bizData,searchResults,setSearchResults,resultSwitch,setResultSwitch,inputTextComp,
inputTextCompArabic,setShowLoadingSprite,routes,setRoutes,ytObjectState,setYtObjectState,showYTresult,setShowYTresult}) {

const [hoveredListIndex,setHoveredListIndex] = useState()

const [inputTextTyped,setInputTextTyped] = useState()

const resultList = useRef([])

const hoveredIndexList = useRef(0)



function displayBorderOnHover(index,listText){
setHoveredListIndex(index)
if(language !== "ar"){
inputTextComp.current.value = listText
}
else{
inputTextCompArabic.current.value = listText
}
}

function copyCurrentInputText(){
if(language !== "ar"){
setInputTextTyped(inputTextComp.current.value)
}
else{
setInputTextTyped(inputTextCompArabic.current.value)
}
}

async function appendCopiedCurrentInputText(){
 if(language !== "ar"){
 inputTextComp.current.value = await inputTextTyped
 }
 else{
 inputTextCompArabic.current.value = await inputTextTyped
 }
}

function resultDisplay(listTextSelected,ytvidURL){
setResultSwitch(false)
setShowLoadingSprite(true)
setInputTextTyped(listTextSelected)
typeof searchResults === "object" && setSearchResults()
fetchVideoWithUrl(ytvidURL)
}

const fetchVideoWithUrl = (urlLink)=>{
resultSwitch && setResultSwitch(false)
typeof searchResults === "object" && setSearchResults()
axios.post(routes.info, {
  url: urlLink
  })
  .then((response)=> {
	setYtObjectState(response.data)
  })
  .then(()=>{
  setShowYTresult(true)
  setShowLoadingSprite(false)
  })
  .catch((error)=> {
   console.log(error);
  })	
}



 return(
 <>
 <section  >
 <div className={styles.inputResultField} style={{display: resultSwitch ? 'block' : 'none'}} onMouseEnter={copyCurrentInputText} onMouseOut={appendCopiedCurrentInputText}>
 <div style={{display:"flex", flexDirection:"row",flexWrap:"wrap",justifyContent:"center"}}>
 {
 typeof searchResults === "object" && searchResults.result.map((result,index)=>
 <div  className={styles.inputResultList} key={result + index} ref={(listComp)=> resultList.current[index] = listComp}
 style={{border: hoveredListIndex === index ? "1px solid rgba(204,204,204,0.7)" : "",display:!result.thumbnails? "none" : "block"}} onMouseOver={()=>displayBorderOnHover(index,result.title)} 
 onClick={()=>resultDisplay(result.accessibility.title,result.link)}>
 <div style={{width:"100%",height:"auto",lineHeight:"90%"}} >
 <img    width="100%" height="100%" src={result.thumbnails[0].url} alt={result.accessibility.title} title={result.accessibility.title}   />
 </div>
 <div style={{padding:"0px 2px 3px 2px"}} title={result.accessibility.title}><b>{result.accessibility.title.slice(0,95)} {result.accessibility.title.length > 95 ? "..." : ""}</b></div>
 </div>
 )
}
 </div>
 </div>
 </section>
 </>
 )  
 }


export default memo(SearchResultDisplayComp)