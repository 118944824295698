
import '../App.css';
import React, {Suspense,useState} from 'react';
import bizData from "../data/data.js"
import HeaderNav from '../header/header-official.js'
import Footer from '../footer/footer-official.js'
import NativeLang from "../data/languageTranslation.js"
import {Helmet} from "react-helmet";


 export default function Terms() {
 return(
  <div>
  <main style={{maxWidth:'100%',overflowX:'Hidden', color:''}} >
    
  <Helmet  htmlAttributes={{ lang: "en" }}>
  <title>Terms Of Service</title>
  <meta name="description" content="Terms Of Service"  />
  </Helmet>
  <HeaderNav ssytbizData={bizData} selectedLanguageSource={"en"} />
  <Suspense>
  <div style={{padding:"2rem",minHeight:"80vh",color:"rgb(42, 42, 42)"}}>
  <h2>TERMS AND CONDITIONS OF USE</h2>

<h2>INTRODUCTION</h2>

<p>Welcome to our website and services provided by SSYT ("we" or "us"). By accessing and using our website, applications, and related services (collectively, the "Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions ("Terms"). These Terms constitute a legal agreement between you and SSYT . If you disagree with any part of these Terms, please refrain from using our Service.</p>

<h2>ACCEPTANCE OF TERMS</h2>

<p>Your access to and use of the Service signifies your acceptance of these Terms. We may periodically modify or update these Terms without prior notice. It is your responsibility to review these Terms regularly. Continued use of the Service after changes have been made constitutes acceptance of the revised Terms.</p>

<h2>ELIGIBILITY</h2>

<p>You must be at least eighteen (18) years old to use our Service. If you are under eighteen (18), please refrain from using our Service, regardless of parental consent. The Service may not be available in certain countries due to technical constraints or local regulations.</p>

<h2>USER CONDUCT</h2>

<p>Your use of the Service must comply with all applicable laws and regulations. You agree not to misuse the Service, access it for unauthorized purposes, or interfere with its functionalities. We reserve the right to limit or terminate your access to the Service at our discretion.</p>

<h2>INTELLECTUAL PROPERTY</h2>

<p>The Service, including applications, databases, and related components, is owned or licensed by us. You are granted a limited, non-transferable license to use the Service subject to these Terms. You agree not to copy, modify, or exploit any part of the Service without explicit authorization.</p>

<h2>USER CONTENT</h2>

<p>While using the Service, you agree not to upload, transmit, or create content that infringes on third-party rights, is offensive, unlawful, or violates these Terms. You are solely responsible for any materials you submit through the Service.</p>

<h2>LINKS TO THIRD-PARTY WEBSITES</h2>

<p>The Service may contain links to third-party websites or services. We are not responsible for the content or activities of these third-party sites. Your access to such sites is at your own risk.</p>

<h2>LIMITATION OF LIABILITY</h2>

<p>The Service is provided "as is" without warranties of any kind. We shall not be liable for any damages, losses, or liabilities arising from your use of the Service, third-party content, or unauthorized access to your data.</p>

<h2>INDEMNIFICATION</h2>

<p>You agree to indemnify and hold us harmless from any claims, damages, or liabilities arising from your use of the Service or violation of these Terms.</p>

<h2>MISCELLANEOUS</h2>

<p>These Terms constitute the entire agreement between you and us regarding the Service and supersede any prior agreements. These Terms are governed by and construed in accordance with the laws of your country.</p>

<h2>CONTACT INFORMATION</h2>

<p>For any questions or concerns regarding these Terms, please contact us at hello@ssyt.tech.</p>

<p>Effective December 4, 2023</p>

  </div>
  <Footer companyData={bizData} language={"en"}  langTrans={NativeLang} />
  </Suspense>
  </main>
  </div>
 )  
 }


 
