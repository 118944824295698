
import {MdClose} from 'react-icons/md'
import {HiMiniLanguage} from 'react-icons/hi2'
import { useState,useEffect,useRef,fowardRef,useCallback,useFlushsync,memo,useLayoutEffect,Suspense } from 'react';
import {FaLanguage} from 'react-icons/fa6'
import styles from './Header.module.css'
import isoLangs from "../data/langs.js"
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';


function Header({ssytbizData,selectedLanguageSource}){
return(
<>
<header className={styles.HeaderMdLg}>
<BigMidHeader companyInfo={ssytbizData} selectedLanguage={selectedLanguageSource}   />
</header>

<header className={styles.HeaderMobile}>
<SmallHeader companyInfo={ssytbizData} selectedLanguage={selectedLanguageSource} />
</header>
</>
)}

export default Header;


const BigMidHeader = memo(function HeaderMdLg({companyInfo,selectedLanguage}){

return(
<>
<div  style={{backgroundColor:companyInfo.header_assets.header_background_color,display:selectedLanguage === "ar" ? "none" : "block"}} >
<div  className={styles.headerContainer}  >
<div  className={styles.header_Inner_container} >
<div  style={{flex:'0.405',display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',color:'white'}} className={styles.o_name}>
<Logo />
<div className={styles.company_name} style={{marginTop:"-0.1rem",lineHeight:"0",fontSize:'1.8rem',display:'flex',alignItems:'center',marginLeft:"3%"}}> <Link to={"/en"} style={{textDecoration:"none",color:"inherit"}}> {companyInfo.company_name_u} </Link></div>
</div>
<div className={styles.langComp} style={{flex:'0.595',display:'flex',alignItems:'center',paddingLeft:'0rem',height:'auto'}}>
<span style={{marginLeft:"auto"}}> <LanguageTranslate language={selectedLanguage} /> </span>
</div>
</div>
</div>
</div>

<div style={{backgroundColor:companyInfo.header_assets.header_background_color,display:selectedLanguage === "ar" ? "block" : "none"}} >
<div className={styles.headerContainer}  >
<div className={styles.header_Inner_container} >

<div className={styles.langComp} style={{flex:'0.595',display:'flex',alignItems:'center',paddingLeft:'0rem',height:'auto',marginRight:"auto"}}>
<span style={{marginRight:"auto"}}> <LanguageTranslate language={selectedLanguage} /> </span>
</div>

<div style={{flex:'0.405',display:'flex',flexDirection:'row',alignItems:'center',color:'white'}} className={styles.o_name}>
<div className={styles.company_name} style={{marginLeft:"auto",marginTop:"-0.1rem",lineHeight:"0",fontSize:'1.8rem',display:'flex',alignItems:'center',marginRight:"3%"}}> <Link to={"/en"} style={{textDecoration:"none",color:"inherit"}} >{companyInfo.company_name_u} </Link></div>
<Logo />
</div>

</div>
</div>
</div>

</>
)}
)



const SmallHeader = memo(function HeaderMobile({companyInfo,selectedLanguage}){


return(
<>

<div  style={{width:"100%",height:"auto",backgroundColor:'rgb(41, 11, 135)',display:selectedLanguage === "ar" ? "none" : "block"}} >
<div  className={styles.headerInerContainer}>
<span style={{width:"9%",height:"auto"}}><Logo /></span>
<span style={{color:companyInfo.header_assets.portfolio_onwer_name_color,marginLeft:"2.5vw",lineHeight:"70%",display:"flex",justifyContent:"center",fontSize:"7vw"}}  > <Link style={{textDecoration:"none",color:"inherit"}} to={"#"}>{companyInfo.company_name_u}</Link> </span>
<span style={{height:"30%",display:"flex",justifyContent:"center",alignItems:"center",marginLeft:"auto"}}>
<Suspense>
<LanguageTranslate language={selectedLanguage} />
</Suspense>
</span>
</div>
</div>


<div style={{width:"100%",height:"auto",backgroundColor:'rgb(41, 11, 135)',display:selectedLanguage === "ar" ? "block" : "none"}} >
<div  className={styles.headerInerContainer}>
<Suspense>
<span style={{height:"30%",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"auto"}}><LanguageTranslate language={selectedLanguage} /></span>
</Suspense>
<span style={{color:companyInfo.header_assets.portfolio_onwer_name_color,marginRight:"2.5vw",lineHeight:"70%",display:"flex",justifyContent:"center",fontSize:"7vw"}}  > <Link style={{textDecoration:"none",color:"inherit"}} to={"#"}> {companyInfo.company_name_u} </Link> </span>
<span style={{width:"9%",height:"auto"}}><Logo /></span>
</div>
</div>

</>
)}
)

	
function Logo(){
return (
<>

<Link className={styles.logo_frame}  to={"/en"}>
<img src={'/favicon.webp'} width="100%" height="100%" alt="ssyt logo" style={{verticalAlign:"bottom"}}/>
</Link>

</>
)
}
	
	
function LanguageTranslate({language}){

const [openLangList,setOpenLangList] = useState(false)

const ref = useRef(null);

const langListComp = useRef(null);


const iconOn = useRef(null);

const iconOff = useRef(null);

const switchRef = useRef(false);

const tempLangArr = []

const [langList,setLangList] = useState("")

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openLangList && ref.current && !ref.current.contains(e.target)) {
         setOpenLangList(false);
      }
      };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openLangList]);
  
  const LangMenueSwitch = ()=>{	
  !openLangList ? setOpenLangList(true) : setOpenLangList(false)
 
  switchRef.current = "true"
 }
 
 const closeLangComp = ()=>{
  setOpenLangList(false)	
 }
  
  useLayoutEffect(()=>{
  openLangList ? langListComp.current.style.display = "block" : langListComp.current.style.display = "none"
  },[openLangList])
  



return (
<>
<div className={styles.languageTrans}  style={{display:"flex",alignItems:"center",justifyContent:"center",height:"10%"}}  ref={ref} >
<span className={styles.selectedLanguage} style={{display:"flex",alignItems:"center",justifyContent:"center",height:"10%"}}  ><FaLanguage onClick={LangMenueSwitch} style={{cursor:"pointer",padding:"0",margin:"0"}} /></span>
<div  id="langList" ref={langListComp} className={language === "ar" ?  styles.languageListArab: styles.languageList}   >
<nav>
<ul style={{listStyleType:"none",margin:0,padding:0}}>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/en"}><li    key={"en"}  style={{display:language === "en" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.en.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/es"}><li  key={"es"}  style={{display:language === "es" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.es.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ar"}><li  key={"ar"}  style={{display:language === "ar" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ar.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/fr"}><li  key={"fr"}  style={{display:language === "fr" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.fr.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/de"}><li  key={"de"}  style={{display:language === "de" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.de.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/hi"}><li  key={"hi"}  style={{display:language === "hi" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.hi.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/id"}><li  key={"id"}  style={{display:language === "id" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.id.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/it"}><li  key={"it"}  style={{display:language === "it" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.it.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ja"}><li  key={"ja"}  style={{display:language === "ja" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ja.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ko"}><li  key={"ko"}  style={{display:language === "ko" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ko.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/zh"}><li  key={"zh"}  style={{display:language === "zh" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.zh.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/pt"}><li  key={"pt"}  style={{display:language === "pt" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.pt.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ru"}><li  key={"ru"}  style={{display:language === "ru" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ru.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/th"}><li  key={"th"}  style={{display:language === "th" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.th.nativeName}</li></Link>
	<Link style={{textDecoration:"none",color:"inherit"}} to={"/tr"}><li  key={"tr"}  style={{display:language === "tr" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.tr.nativeName}</li></Link>
</ul>
</nav>
</div>

</div>
</>
)
}







	{/*
langData.map(([key,value],index)=>

<li className={styles.languageList_langs}  key={index+value.name} onClick={()=>setOpenLangList(false)}><a href={`/${key}`}>{value.nativeName}</a></li>

)	
	*/}