import {Link} from 'react-router-dom';
import {PiSmileySadThin} from 'react-icons/pi'

export default function NoMatch() {
  return (
    <div style={{height:"100vh",backgroundColor:"rgb(41, 11, 135)"}}>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",height:"80vh",padding: 20,color:"white"}}>
	<span style={{fontSize:"13em",lineHeight:"50%"}}> <PiSmileySadThin /> </span>
      <h2>404: Page Not Found</h2>
	  <nav style={{ margin: 0 }}>
		  {/* <p>This page could not be found.</p> */}
      <span> Return to <Link style={{textDecoration:"none",color:"inherit",padding: 5  }} to="/" >SSYT</Link></span>
      </nav>
    </div>
	</div>
  );
}