const languageTranslations = {
    "axtryendheu": {},
    "langTransData": {
        "header": {
            "title": "Pengunduh Youtube",
            "discription": "Pengunduh YouTube gratis terbaik yang tersedia! Unduh video YouTube tanpa batas, simpan tautan & informasi video YouTube, setelah mengunduh video dari YouTube. ssyoutube adalah Pengunduh YouTube terbaik untuk mengunduh tutorial YouTube dan berbagai video YouTube penting, karena jarangnya menyimpan tautan video YouTube & fitur informasi. ssyoutube, Adalah pengunduh YouTube tingkat lanjut, dengan pengunduhan berkecepatan tinggi, mendukung berbagai format video dan sangat aman."
        },
        "search_result_comp": {
            "id": {
                "header_text": "Pengunduh YouTube gratis terbaik",
                "header_text_sub": "Unduh Video YouTube | simpan tautan & info video YouTube",
                "placeholder_text": "Tempel tautan video YouTube Anda di sini",
                "download_button_text": "Unduh",
                "result_header_text": "Unduh video sebagai:",
                "savelink_button_text": "Simpan tautan video YouTube",
                "savelink_button_text_title": "Simpan URL video YouTube, judul, saluran, durasi, tanggal",
                "video_text": "Video",
                "show_more_text": "Menampilkan lebih banyak",
                "show_less_text": "Show less",
                "author_text": "Saluran ",
                "duration_text": "Durasi",
                "input_box_title": "Tempel tautan video YouTube Anda di sini",
                "fetching_data": "Mengambil Video YouTube....."
            }
        },
        "saved_youtube_links_section": {
            "id": {
                "input_placeholder_text": "Temukan video YouTube yang disimpan oleh $        ",
                "input_placeholder_text_title": "Temukan video YouTube yang disimpan oleh $",
                "select_a": "Judul",
                "select_b": "Pengarang",
                "select_c": "Durasi",
                "select_d": "Tanggal",
                "select_e": "Tautan",
                "select_f": "Keterangan",
                "select_g": "Saluran",
                "select_value_a": "s_title",
                "select_value_b": "s_author",
                "select_value_c": "duration",
                "select_value_d": "date",
                "select_value_e": "url",
                "input_search_alert_a": "Tidak dapat mencocokkan video YouTube mana pun",
                "input_search_alert_a_cont": "kamu menyelamatkan",
                "input_search_alert_b": "ditemukan",
                "input_search_alert_b_cont": "Video Youtube",
                "input_search_alert_b_cont_single": "Video Youtube",
                "input_search_alert_c": "0 info video YouTube disimpan",
                "input_search_alert_c_cont": "diselamatkan",
                "copy_links_button_text_top": "Menyalin",
                "copy_links_info_button_text_top": "Menyalin",
                "copy_links_info_button_text_top_cont": "& informasi",
                "unsaved_videomsg_a": "Anda belum menyimpan tautan video YouTube apa pun",
                "unsaved_videomsg_b": "Download video YouTube, lalu simpan link video YouTube secara manual dengan tombol 'simpan link video Youtube'.",
                "unsaved_videomsg_c": "ATAU",
                "unsaved_videomsg_d": "Aktifkan 'simpan otomatis', untuk menyimpan tautan Video Youtube Anda secara otomatis setelah setiap pengunduhan.",
                "select_button_title": "Pilih video YouTube",
                "select_button_copy_title": "Salin tautan YouTube",
                "select_button_delete_title": "Hapus tautan YouTube",
                "select_button_visit_title": "Lihat video di Youtube",
                "select_button_download_title": "Unduh Video Youtube",
                "auto_save_button_text": "Simpan otomatis ",
                "auto_save_button_title_text": "Simpan tautan secara otomatis setelah mengunduh",
                "copy_youtube_links_button_text": "Salin tautan YouTube",
                "copy_youtube_links_button_title_text": "Ekstrak semua tautan YoTtube yang disimpan",
                "copy_youtube_links_info_button_text": "Salin tautan & info YouTube",
                "copy_youtube_links_info_button_title_text": "Ekstrak semua tautan & info YouTube yang tersimpan",
                "auto_save_button_text_switch_text_on": "PADA",
                "auto_save_button_text_switch_text_off": "MATI",
                "author": "Pengarang",
                "channel": "Saluran",
                "duration": "Durasi",
                "link": "tautan",
                "links": "tautan",
                "input_search_alert_b_cont_info_text": "info",
                "delete_saved_yt_links_file": "Hapus tautan video Youtube",
                "delete_saved_yt_links_file_msg": "Apakah Anda ingin menghapus tautan video Youtube yang disimpan",
                "delete_saved_yt_links_yes": "Ya",
                "delete_saved_yt_links_no": "TIDAK",
                "delete_saved_yt_links_statusMsg": "Selesai!",
                "no_loc": "Anda belum menyimpan tautan video Youtube apa pun",
				"saved_YT_links_header":"Tautan YouTube Tersimpan"
            }
        },
        "back_up_manager": {
            "id": {
                "back_up_button_text": "Tautan Cadangan",
                "retrive_button_text": "Ambil Tautan",
                "retrive_button_title": "Ambil tautan cadangan",
                "back_up_button_text_mb": "Cadangan",
                "retrive_button_text_mb": "Ambil Tautan",
                "back_up_placeholder_text": "Tautan cadangan dengan string unik",
                "retrive_placeholder_text": "Ketik string unik Anda",
                "back_up_input_title": "String unik akan digunakan untuk mengidentifikasi data yang Anda simpan",
                "retrive_input_title": "String unik yang Anda gunakan untuk cadangan",
                "back_up_status": "Berhasil dicadangkan",
                "retrive_status": "Tautan Youtube berhasil diambil",
                "back_up_err": "gunakan nama file lain",
                "back_up_err_no_loc": "Anda belum menyimpan tautan Youtube",
                "back_up_retive_err": "Tidak dapat menyelesaikan permintaan Anda",
                "more_text": "Cadangkan Tautan YouTube",
                "less_text": "Sembunyikan Tautan YouTube cadangan",
                "more_tools_avail": "Cadangkan tautan Youtube & alat pengunduhan batch tersedia",
                "batch_download_header_text": "Unduhan batch video Youtube",
                "batch_download_textarea_placeholder_text": "Maksimum $ link Youtube",
                "batch_download_button_text": "Unduh Batch Semua",
                "batch_download_button_text_720": "Unduh Semua - 720p",
                "batch_download_success": "Berhasil mendownload semuanya",
                "batch_download_error": "Pengunduhan batch gagal",
                "batch_download_error_too_many_urls": "Url Youtube tidak boleh melebihi $",
                "invalid_yt_url": "Terdeteksi url Youtube tidak valid",
                "Maximum_batch_download": 20,
                "batch_download_textarea_title": "Download banyak video Youtube sekaligus",
                "download_terminated": "Pengunduhan Batch telah dihentikan",
                "download_terminated_title": "Hentikan pengunduhan batch",
                "add_youtube_links_err": "Tambahkan tautan video Youtube"
            }
        },
        "product_text": {
            "id": {
                "about_product_a": "<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n\t\t\t\tssyoutube sangat cocok untuk mendownload tutorial YouTube dan berbagai video YouTube penting, karena jarangnya menyimpan link video YouTube dan fitur informasinya. </font><font style=\"vertical-align: inherit;\">Pengunduh video YouTube, termasuk pelajar dan berbagai e-pelajar, akhirnya memiliki kesempatan langka untuk menyimpan tautan dan informasi video YouTube setelah mengunduh video YouTube.\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                ssyoutube adalah pengunduh YouTube online terbaik dan paling andal. </font><font style=\"vertical-align: inherit;\">Berapa banyak pengunduh YouTube yang dapat menyimpan tautan video YouTube setelah mengunduh video YouTube?\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                Unduh video YouTube dan simpan tautannya, sehingga Anda tidak kehilangan tautan ke video YouTube penting yang telah Anda unduh. </font><font style=\"vertical-align: inherit;\">ssyoutube adalah pengunduh YouTube online gratis dan akan tetap gratis selamanya.\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                Selain menyimpan tautan dan informasi tentang video YouTube yang diunduh, tautan dan informasi video YouTube yang disimpan juga dapat ditemukan melalui judul, penulis, deskripsi, tanggal, dan URL. </font><font style=\"vertical-align: inherit;\">Memberi pengguna banyak pilihan untuk dengan mudah menemukan video YouTube yang diunduh ketika mereka lupa judul video, bingung, atau memiliki banyak video yang diunduh di sistem mereka.\n\t\t\t\t</font></font></p>\n\t\t\t\t<p></p>",
                "about_product_b": "<span translate='no'>{bizData.company_name}</span>tidak bermaksud untuk bersaing dengan pengunduh video Youtube Gratis yang terkenal di dunia (ssyoutube.com), namun pujilah kehebatannya dengan fitur-fitur yang sangat dibutuhkan pengguna, namun saat ini tidak tersediassyoutube.com. <span translate='no'>{bizData.company_name}</span>, menyediakan fitur tertentu seperti menyimpan dan mengelola tautan dan informasi video Youtube yang diunduh, yang saat ini tidak tersedia dissyoutube.com.<span translate='no'>{bizData.company_name}</span>adalah alternatif yang baik untukssyoutube.com.",
                "supported_platforms_header_text": "Platform yang Didukung",
                "supported_platforms_paragraph": "mendukung semua platform dan perangkat.",
                "download_yt_video_header_text": "Cara Download Video Youtube",
                "download_yt_video_paragraph": "Paste link Youtube pada kolom input link Youtube di atas, lalu pilih format video pilihan Anda melalui berbagai tombol download.",
                "save_yt_video_header_text": "Cara Menyimpan Link Video YouTube Setelah Download",
                "save_yt_video_text_a": "1. Klik tombol \"Simpan tautan video Youtube\" setelah mengunduh video.",
                "save_yt_video_text_or": "ATAU",
                "save_yt_video_text_b": "2. Klik tombol simpan otomatis untuk menyimpan tautan video Youtube secara otomatis setelah setiap pengunduhan.",
                "save_yt_video_text_paragraph": "Pernahkah Anda membuang-buang waktu berharga Anda untuk mencari video Youtube yang sebelumnya Anda unduh di sistem Anda atau tidak dapat menemukan tautan ke video Youtube yang diunduh sebelumnya? Simpan tautan dan informasi video Youtube yang diunduh dengan<span translate='no'>{bizData.company_name}</span>.",
                "were_yt_links_saved_header_text": "Di Mana Tautan Youtube Disimpan",
                "were_yt_links_saved_paragraph": "Tautan dan informasi video Youtube yang diunduh disimpan dengan aman di penyimpanan lokal browser Anda dan secara opsional dicadangkan di<span translate='no'>{bizData.company_name}</span>basis data.",
                "how_safe_header_text": "Apakah Aman Menyimpan Tautan Video Youtube Di Browser Saya",
                "how_safe_paragraph": "Sebagian besar browser web memiliki basis data penyimpanan lokal, untuk menyimpan data di browser. Data yang disimpan di penyimpanan lokal browser dirangkai sebelum disimpan untuk alasan keamanan. 100% aman untuk menyimpan tautan dan informasi video Youtube yang diunduh<span translate='no'>{bizData.company_name}</span>. Mohon verifikasi klaim kami dengan berbagai alat keamanan situs web secara online.",
                "how_to_backup_header_text": "Cara Mencadangkan Link & Info Youtube",
                "how_to_backup_paragraph": "Tautan dan informasi Youtube yang disimpan dicadangkan dengan string unik satu kali untuk identifikasi dan pengambilan di<span translate='no'>{bizData.company_name}</span>basis data. Ketik string unik Anda di kolom input, lalu klik tombol \"Tautan Cadangan\".",
                "how_to_batch_download_header": "Cara mengunduh video Youtube secara batch",
                "how_to_batch_download_text": "Rekatkan semua url video Youtube di area teks unduhan batch, lalu klik 'Tombol Unduhan batch semua'. Semua link url Youtube harus ditempatkan pada baris terpisah dan tidak boleh dipisahkan dengan \"koma (',')\".",
                "services_free_ytd_header": "Pengunduh YouTube Gratis",
                "services_free_ytd_header_p": "Pengunduh YouTube Gratis",
                "services_save_ytd_header": "Simpan Tautan YouTube",
                "services_save_ytd_header_p": "Simpan dan kelola tautan video YouTube yang diunduh.",
                "services_batch_ytd_header": "Unduhan Batch",
                "services_batch_ytd_header_p": "Unduh banyak video YouTube sekaligus.",
                "privacy_header": "Maukah Anda Menghormati Privasi Saya",
                "privacy_text": "Kami menghormati privasi Anda dan tidak akan pernah memanfaatkan Anda. Kami tidak akan pernah mengumpulkan, menyimpan, atau menjual informasi pribadi Anda kepada pihak ketiga. Kami tidak memasang cookie, pelacak, bot, dan sejenisnya saat Anda mengunjungi atau mengunduh video YouTube <span translate='no'>{bizData.company_name}</span>.",
                "product_story": "\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nKami mengembangkan ssyoutube sebagai respons terhadap meningkatnya kebutuhan untuk menyimpan tautan dan informasi dari setiap tutorial YouTube yang diunduh dari YouTube, khususnya di kalangan pelajar dan berbagai pembelajar elektronik yang mengandalkan YouTube untuk tujuan pendidikan. </font><font style=\"vertical-align: inherit;\">ssyoutube adalah hasil kerja keras dan upaya kami untuk menyediakan pengunduh baru bagi komunitas pengunduh video YouTube yang mengatasi masalah pengunduhan video mereka saat ini.\n</font></font></p>\n<b><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nKelebihan Menyimpan Tautan dan Info Video YouTube Setelah Mengunduh Video YouTube:\n</font></font></b>\n<ul>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nSiswa dan berbagai pembelajar elektronik dapat dengan mudah membagikan tautan URL ke tutorial YouTube penting yang pernah mereka gunakan untuk tujuan pendidikan di masa lalu atau sekarang dengan rekan siswa dan teman ketika diperlukan.\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nPengunduh video YouTube dapat dengan mudah mengunduh ulang video YouTube jika mereka menghapus video atau memformat sistemnya, tanpa membuang waktu mencari berbagai video YouTube yang telah mereka unduh sebelumnya.\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nAlat simpan tautan YouTube ssyoutube memudahkan pengunduh video YouTube untuk menemukan, mengelola, mengatur, dan menavigasi banyak video YouTube yang disimpan di sistem mereka.\n</font></font></p>\n</li>\n</ul>\n"
            }
        },
        "save_link_status_msg": {
            "id": {
                "success": "Info video YouTube berhasil disimpan",
                "error": "Kesalahan! Tautan YouTube ini telah disimpan sebelumnya",
                "default_msg": ""
            }
        },
        "footer": {
            "id": {
                "services_a": "Unduh video YouTube",
                "services_b": "Simpan tautan & info video YouTube",
                "services_c": "Download banyak Video Youtube sekaligus",
                "services_by_text": "Pengunduh Video YouTube oleh",
                "menu_about": "Tentang kami",
                "menu_privacy": "Kebijakan pribadi",
                "menu_terms": "Ketentuan Layanan",
                "menu_contact": "Kontak"
            }
        }
    }
}

export default languageTranslations