import { useState,useEffect,useLayoutEffect,useRef,useCallback,Suspense,memo,lazy } from 'react';
import { BrowserRouter as Router, Routes, Route,redirect,Navigate } from 'react-router-dom';
import styles from './inputNresult.module.css'
import {FaLanguage,} from 'react-icons/fa'
import {BiRightArrowAlt} from 'react-icons/bi'
import {FaFacebook, FaTwitter, FaTiktok, FaYoutube, FaCopyright, FaArrowUp, FaArrowRight, FaUserFriends,FaShoppingBasket,FaHamburger,FaDownload} from 'react-icons/fa'
import {LuConstruction} from 'react-icons/lu'
import BeatLoader from "react-spinners/BeatLoader";
import axios from 'axios'
import Infoaa from '../revInfo/AT/at.js'
import YtLinkResultComp from './youtubeLinkResult.js'
import YtSearchResultComp from './searchResultComp.js'
const SavedLinkComp = lazy(() => import('../savedLinkComp/manageSavedLinks.js'));



function PasteLinkHeader({language,langTrans,bizData}) {

const [languageType, setLanguageType] = useState('en')


const langWithLongChar = ["ja","hi","es","fr","de","it","pt","ru","th","tr"]

 return(
 <div>
 <div  className={styles.inputNresultsCompSectionHeader}>
 <section  >
 <center>
 <div >

 </div>

 <div  className={langWithLongChar.indexOf(language) === -1 ? styles.inputNresultsComp : styles.inputNresultsCompLongChar}>
 <center><h1 className={styles.inputNresultsComp_h1}  >{langTrans.langTransData.search_result_comp[language].header_text}</h1></center>
 <center><h2 className={styles.inputNresultsComp_h3}  >{langTrans.langTransData.search_result_comp[language].header_text_sub}</h2></center>
 
 
 
 </div>
 
 </center>
 </section>
 


 
 </div>

 </div>
 )  
 }


export default PasteLinkHeader




