
import '../App.css';
import React, {Suspense,useState} from 'react';
import bizData from "../data/data.js"
import HeaderNav from '../header/header-official.js'
import Footer from '../footer/footer-official.js'
import NativeLang from "../data/languageTranslation.js"
import {Helmet} from "react-helmet";


 export default function Privacy() {
 return(
  <div>
  <main style={{maxWidth:'100%',overflowX:'Hidden', color:''}} >
    
  <Helmet  htmlAttributes={{ lang: "en" }}>
  <title>Privacy Policy</title>
  <meta name="description" content="Privacy Policy"  />
  </Helmet>
  <HeaderNav ssytbizData={bizData} selectedLanguageSource={"en"} />
  <Suspense>
  <div style={{padding:"2rem",minHeight:"80vh",color:"rgb(42, 42, 42)"}}>
  <h1> Privacy Policy</h1>
  
<p> This Privacy Policy explains how SSYT collects, uses, maintains, and discloses information gathered from users ("User," "You") of our platform ("Platform," "Service"). By using SSYT, you agree to the terms outlined in this Privacy Policy.</p>

<h2> 1. Information Collection </h2>
<p>
We don't collect any information from you and will never. Although our affiliates/advertisers may or will collect your personal information when you click their links.
</p>
<h2> 3. Cookies and Similar Technologies </h2>
<p>
Our Service don't use cookies and similar technologies to enhance user experience, analyze usage patterns, and improve our Service. Our various affiliates will or may use cookies for advert purposes only. Users can manage cookie settings through their browsers, but disabling cookies may limit access to certain features of the Service.
</p>
<h2> 4. Children's Privacy </h2>
<p>
Protecting children's privacy is paramount to us. If we discover that personal data has been collected from a child, we will take steps to delete it promptly.
</p>
<h2> 5. Data Sharing </h2>
<p>
We may share non-personal data with affiliates, service providers, or third parties for research or analytics purposes. Links to third-party websites or applications within our Service are beyond our control, and users are encouraged to review their privacy policies.
</p>
<h2> 6. User Rights </h2>
<p>
Users residing in regions covered by privacy regulations have specific rights regarding their personal information. These rights include access, rectification, erasure, restriction of processing, data portability, objection to processing, withdrawal of consent, and the right to lodge complaints.
</p>
<h2>7. Contact Information</h2>

<p> For inquiries, to exercise user rights, or questions regarding this Privacy Policy, please contact us at hello@ssyt.tech.</p>

<p>Effective December 4, 2023</p>


  </div>
  <Footer companyData={bizData} language={"en"}  langTrans={NativeLang} />
  </Suspense>
  </main>
  </div>
 )  
 }


 
