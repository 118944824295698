
import styles from './footer.module.css'
import {FaFacebook, FaTwitter, FaTiktok, FaYoutube,FaLinkedin, FaCopyright, FaArrowUp, FaArrowRight, FaUserFriends,FaShoppingBasket,FaHamburger,FaQuoteRight} from 'react-icons/fa'
import { useState,useEffect,useLayoutEffect,useRef } from 'react';
import { RxDividerVertical } from "react-icons/rx";
import {Link } from 'react-router-dom';


function Footer({companyData,language,langTrans}){

return(
<>
<footer>
<div className={styles.footer_main_container} style={{color:companyData.footer_styles.text_color, backgroundColor:companyData.footer_styles.bg_color}}>
<div className={styles.footer_main_container_top}  style={{borderBottom:'1px solid '+companyData.footer_styles.border_bottom_color}}>
<div className={styles.footer_main_container_top_services}>
{langTrans.langTransData.footer[language].services_a} | {langTrans.langTransData.footer[language].services_b}
</div>

<div className={styles.footer_main_container_top_services_mobile}>
<div> {langTrans.langTransData.footer[language].services_a} </div>
<div> {langTrans.langTransData.footer[language].services_b} </div>
</div>


</div>
<div className={styles.footer_main_container_top_menu}>
<div className={styles.footer_main_container_top_menu_links}> <Link style={{textDecoration:"none",color:"inherit"}}  to='/about'> {langTrans.langTransData.footer[language].menu_about}</Link> </div> 
<div className={styles.footer_main_container_top_menu_links}> <Link style={{textDecoration:"none",color:"inherit"}}  to='/privacy'> {langTrans.langTransData.footer[language].menu_privacy}</Link> </div> 
<div className={styles.footer_main_container_top_menu_links}> <Link style={{textDecoration:"none",color:"inherit"}}  to='/terms'> {langTrans.langTransData.footer[language].menu_terms}</Link> </div> 
<div className={styles.footer_main_container_top_menu_links}> <Link style={{textDecoration:"none",color:"inherit"}}  to='/contact'> {langTrans.langTransData.footer[language].menu_contact}</Link> </div>
</div>
<div style={{display:'flex',alignItems:'center',justifyContent:'center'}} className={styles.footer_main_container_bottom}> <span style={{verticalAlign:'bottom',fontSize:'0.79rem',lineHeight:'70%',paddingLeft:'0.2rem',paddingRight:'0.2rem',border:'0px solid red'}}> &#169; </span> {new Date().getFullYear()} {langTrans.langTransData.footer[language].services_by_text} {companyData.company_url}  </div>
</div>
</footer>

</>
)}

export default Footer
