const isoLangs = {
	 "en":{
        "name":"English",
        "nativeName":"English"
    },
     "es":{
        "name":"Spanish; Castilian",
        "nativeName":"Español"
    },
    "ar":{
        "name":"Arabic",
        "nativeName":"العربية"
    },
    "fr":{
        "name":"French",
        "nativeName":"Français"
    },
  
    "de":{
        "name":"German",
        "nativeName":"Deutsch"
    },
  
    "hi":{
        "name":"Hindi",
        "nativeName":"हिन्दी, हिंदी"
    },
 
    "id":{
        "name":"Indonesian",
        "nativeName":"Bahasa Indonesia"
    },
  
    "it":{
        "name":"Italian",
        "nativeName":"Italiano"
    },
   
    "ja":{
        "name":"Japanese",
        "nativeName":"日本語"
    },
  
    "ko":{
        "name":"Korean",
        "nativeName":"한국어 (韓國語)"
    },
	 "zh":{
        "name":"Chinese",
        "nativeName":"中文 (Zhōngwén)"
    },
	 "pt":{
        "name":"Portuguese",
        "nativeName":"Português"
    },
    "ru":{
        "name":"Russian",
        "nativeName":"русский язык"
    },
    "th":{
        "name":"Thai",
        "nativeName":"ไทย"
    },
    "tr":{
        "name":"Turkish",
        "nativeName":"Türkçe"
    }
    }
	
	 export default isoLangs
	 
	 
	 /*https://stackoverflow.com/questions/3217492/list-of-language-codes-in-yaml-or-json*/
	 
	 
