
import '../App.css';
import React, {Suspense,useState} from 'react';
import bizData from "../data/data.js"
import HeaderNav from '../header/header-official.js'
import Footer from '../footer/footer-official.js'
import NativeLang from "../data/languageTranslation.js"
import {Helmet} from "react-helmet";


 export default function Contact() {
 return(
  <div>
  <main style={{maxWidth:'100%',overflowX:'Hidden', color:''}} >
    
  <Helmet  htmlAttributes={{ lang: "en" }}>
  <title>Contact us</title>
  <meta name="description" content="Contact us"  />
  </Helmet>
  <HeaderNav ssytbizData={bizData} selectedLanguageSource={"en"} />
  <Suspense>
  <div style={{padding:"2rem",minHeight:"80vh",color:"rgb(42, 42, 42)"}}>
  <h1> Contact us </h1>
  
   <p>hello@ssyt.tech</p>
  </div>
  <Footer companyData={bizData} language={"en"}  langTrans={NativeLang} />
  </Suspense>
  </main>
  </div>
 )  
 }


 
