
import '../App.css';
import React, {Suspense,useState} from 'react';
import bizData from "../data/data.js"
import HeaderNav from '../header/header-official.js'
import Footer from '../footer/footer-official.js'
import NativeLang from "../data/languageTranslation.js"
import {Helmet} from "react-helmet";


 export default function AboutUs() {
 return(
  <div>
  <main style={{maxWidth:'100%',overflowX:'Hidden', color:''}} >
    
  <Helmet  htmlAttributes={{ lang: "en" }}>
  <title>About us</title>
  <meta name="description" content="SSYT Youtube Video Downloader, gives you have the power to download Youtube videos, and save their links, so you don't lose the links to
  important Youtube videos you have downloaded"  />
  </Helmet>
  <HeaderNav ssytbizData={bizData} selectedLanguageSource={"en"} />
  <Suspense>
  <div style={{padding:"2rem",minHeight:"80vh",color:"rgb(42, 42, 42)"}}>
  <h1> ABOUT US</h1>
  <p>SSYT Youtube Video Downloader, gives you have the power to download Youtube videos, and save their links, so you don't lose the links to
  important Youtube videos you have downloaded. Apart from saving links and information about Youtube videos downloaded, 
  saved Youtube video links and information can also be found via their titles, author, description, date, and url.
  Giving users lots of options to easily find downloaded Youtube videos when they forget video titles, get confused, or have lots of downloaded videos in their system.
  Saved Youtube video links can also be backed up on the SSYT data base.
  </p>
  </div>
  <Footer companyData={bizData} language={"en"}  langTrans={NativeLang} />
  </Suspense>
  </main>
  </div>
 )  
 }


 
