
import { useState,useEffect,useLayoutEffect,useRef,fowardRef,useCallback,useFlushsync,Suspense } from 'react';
import NativeLang from "../../data/languageTranslationID.js"
import bizData from "../../data/data.js"
import {Helmet} from "react-helmet";

import AppID from '../../App.js'


export default function Indonasian({compPadding}) {


const languageType = "id"
 return(
  <main  style={{maxWidth:'100%',overflowX:'Hidden'}} >
  
  <Helmet  htmlAttributes={{ lang: languageType }}>
  <title>{NativeLang.langTransData.header.title} - {bizData.company_url}</title>
  <meta name="description" content={NativeLang.langTransData.header.discription}  />
  
  <link rel="canonical" href="https://www.ssyoutube.pw/id" />
  <link rel="alternate" href="https://www.ssyoutube.pw/en/" hreflang="en"  />
  <link rel="alternate" href="https://www.ssyoutube.pw/" hreflang="x-default"/>
  <link rel="alternate" href="https://www.ssyoutube.pw/es/" hreflang="es" />
  <link rel="alternate" href="https://www.ssyoutube.pw/de/" hreflang="de" />
  <link rel="alternate" href="https://www.ssyoutube.pw/ar/" hreflang="ar" />
  <link rel="alternate" href="https://www.ssyoutube.pw/pt/" hreflang="pt" />
  <link rel="alternate" href="https://www.ssyoutube.pw/ru/" hreflang="ru" />
  <link rel="alternate" href="https://www.ssyoutube.pw/id/" hreflang="id" />
  <link rel="alternate" href="https://www.ssyoutube.pw/tr/" hreflang="tr" /> 
  <link rel="alternate" href="https://www.ssyoutube.pw/fr/" hreflang="fr" />
  <link rel="alternate" href="https://www.ssyoutube.pw/it/" hreflang="it" />
  <link rel="alternate" href="https://www.ssyoutube.pw/hi/" hreflang="hi" />
  <link rel="alternate" href="https://www.ssyoutube.pw/ja/" hreflang="ja" />
  <link rel="alternate" href="https://www.ssyoutube.pw/ko/" hreflang="ko" />
  <link rel="alternate" href="https://www.ssyoutube.pw/th/" hreflang="th" />
  <link rel="alternate" href="https://www.ssyoutube.pw/zh/" hreflang="zh" />
  
  <link rel="dns-prefetch" href="//ajax.googleapis.com" />
  <link rel="dns-prefetch" href="//ssl.google-analytics.com" />
  <link rel="dns-prefetch" href="//www.google-analytics.com" /> 
  <link rel="preconnect" href="https://www.i.ytimg.com" />
  <link rel="dns-prefetch" href="https://www.i.ytimg.com" />
  </Helmet>
  
  
  <AppID  languageType={languageType} nativeLanguage={NativeLang} compPadding={compPadding}  /> 
  </main>
 )  
 }


