import { useState,useEffect,useLayoutEffect,useRef,useCallback,Suspense,memo,lazy } from 'react';
import styles from './inputNresult.module.css'
import {FaYoutube,FaDownload} from 'react-icons/fa'
import axios from 'axios'
import  bytes from 'bytes'
import { DateTime, Duration } from "luxon";
import { HiMiniSpeakerXMark } from "react-icons/hi2";
import { GiSpeakerOff } from "react-icons/gi";



function YtLinksComp({language,langTrans,bizData,ytObjectState,setYtObjectState,localStorageExist,setLocalStorageExist,savedLinks,setSavedLinks,setIsSelectedLink,
downloadFormats,renderFormatsNum,setRenderFormatsNum,showYTresult,setShowYTresult,msgSavingStatus,setMsgSavingStatus,routes,setRoutes}) {


const ytObject = typeof ytObjectState === "object" ? {	
youtube_links : {
[ytObjectState.webpage_url] :{
url:ytObjectState.webpage_url,
title:ytObjectState.title,
dp:ytObjectState.thumbnail,
author:ytObjectState.uploader,
channel:ytObjectState.channel,
description:ytObjectState.description ? ytObjectState.description.slice(0,200) :  null,
duration:ytObjectState.duration_string,
selected:false,
copied:false,
date : new Date().toLocaleDateString()
}
},
selected_values:{
auto_save_links : savedLinks?.selected_values.auto_save_links ? savedLinks.selected_values.auto_save_links : false ,
back_up: false,
unique_string:""
}
} : null


const linkSavingStatusMsgs = {
sucess :{
msg_info : langTrans.langTransData.save_link_status_msg[language].success,
color: "inherit"
},
error :{
msg_info : langTrans.langTransData.save_link_status_msg[language].error,
color: "rgb(245, 46, 46)"	
}	
}

const saveLink = async(autoSaveYTlink)=>{
if(typeof ytObjectState !== "undefined" || null ){
!localStorageExist && setLocalStorageExist(true)
if(!window.localStorage.getItem("ssytLinks833gdhd8Q82")){
await window.localStorage.setItem("ssytLinks833gdhd8Q82",JSON.stringify({...ytObject}))
await setSavedLinks({...ytObject})
!autoSaveYTlink && await setMsgSavingStatus(linkSavingStatusMsgs.sucess)
}
else{
addNewDataToDb(
ytObjectState.webpage_url,
ytObjectState.title,
ytObjectState.thumbnail,
ytObjectState.uploader,
ytObjectState.description ? ytObjectState.description.slice(0,200) :  null,
ytObjectState.channel,
ytObjectState.duration_string,
autoSaveYTlink
)
}
}
else{
return false
}
}


const addNewDataToDb = async(urlKeyString,title,dp,author,description,channel,duration,autoSave)=> {
const storedData = await window.localStorage.getItem("ssytLinks833gdhd8Q82")
const parseData = await JSON.parse(storedData)
const ytLinksData = parseData["youtube_links"]
const selectedSettings = parseData["selected_values"]
if(!ytLinksData.hasOwnProperty(urlKeyString)){
await setIsSelectedLink([])
const newestData = await {...ytLinksData,[urlKeyString]:{
url:urlKeyString,
title:title,
dp:dp,
author:author,
description:description.slice(0,250),
channel:channel,
duration:duration,
selected:false,
copied:false,
date : new Date().toLocaleDateString()
}
}
const buildData = await  {youtube_links : newestData ,selected_values:selectedSettings}
await window.localStorage.setItem("ssytLinks833gdhd8Q82",JSON.stringify(buildData))
await setSavedLinks(buildData)
!autoSave && await setMsgSavingStatus(linkSavingStatusMsgs.sucess)	
}
else{
!autoSave && await setMsgSavingStatus(linkSavingStatusMsgs.error)	
}
}

const downloadVideo = async(vidId,url)=>{
  const encodeTitle = await url+"&title="+ytObjectState.title.replaceAll(" ","%20")
  const link = document.createElement("a");
  link.href = encodeTitle;
  link.setAttribute("download", ytObjectState.title);
  link.click();
  window.URL.revokeObjectURL(link.href)
  window.localStorage.getItem("ssytLinks833gdhd8Q82") !== null && autoSaveLink()
}


const autoSaveLink = async()=>{
if(window.localStorage.getItem("ssytLinks833gdhd8Q82") !== null){
const storedDataAS = await window.localStorage.getItem("ssytLinks833gdhd8Q82")
const parseDataAS = await JSON.parse(storedDataAS)
const autoSaveLinks = await parseDataAS.selected_values.auto_save_links
if(autoSaveLinks){
addNewDataToDb(
ytObjectState.videoDetails.video_url,
ytObjectState.videoDetails.title,
ytObjectState.videoDetails.thumbnails[2].url,
ytObjectState.videoDetails.author.name,
ytObjectState.videoDetails.description.slice(0,200),
ytObjectState.videoDetails.ownerChannelName.channel,
ytObjectState.videoDetails.lengthSeconds,
true
)
}
else
{
return false	
}
}
else{
saveLink(true)
}
}

const InvalidFormats = [
    "sb2",
    "sb1",
    "sb0",
    "233",
    "234",
    "139",
    "249",
    "250",
    "140",
    "251",
    "17",
    "269",
    "160",
    "603",
    "278",
    "229",
    "133",
    "604",
    "242",
    "230",
    "134",
    "605",
    "243",
    "231",
    "135",
    "606",
    "244",
	"597",
	"598",
	"394",
	"395",
	"396",
	"397",
	"136",
	"247"
]



 return(
 <>
 <section className={styles.downLoadVideoSection} style={{display:showYTresult ? "block" : "none"}}>
 <center>
 <div>
 <div className={styles.downLoadVideoSectionHeader}> <b> {langTrans.langTransData.search_result_comp[language].result_header_text} </b> </div>
 <div className={styles.downLoadVideoSection_download_frame}>
 <div className={styles.downLoadVideoSection_download_L}>
 <div className={styles.downLoadVideoSection_download_L_image_frame}>
  <img    width="100%" height="100%" src={typeof ytObjectState === "object" ? ytObjectState.thumbnail : ""} alt={typeof ytObjectState === "object" ? ytObjectState.title : ""} tittle={typeof ytObjectState === "object" ? ytObjectState.title : ""}  />
 </div>
 
 <div className={styles.downLoadVideoSection_download_L_title}   >
 <b> {typeof ytObjectState === "object" ? ytObjectState.title : "" }</b>
 </div>
 <div  className={styles.downLoadVideoSection_download_L_author}>
 {langTrans.langTransData.search_result_comp[language].author_text}: {typeof ytObjectState === "object" ? ytObjectState.channel : "" }
 </div>
 <div className={styles.downLoadVideoSection_download_L_duration}>
{langTrans.langTransData.search_result_comp[language].duration_text}: {typeof ytObjectState === "object" ?  ytObjectState.duration_string : "" }
 </div>
 <div style={{marginTop:'1rem',marginBottom:'0.2rem'}}>
 <button  id="save-youtube-video-link-btn" aria-label={langTrans.langTransData.search_result_comp[language].savelink_button_text}  className={styles.saveLink} title={langTrans.langTransData.search_result_comp[language].savelink_button_text_title} onClick={()=>saveLink(false)}>
 {langTrans.langTransData.search_result_comp[language].savelink_button_text}
 </button>
 </div>
 <div style={{height:'1em',marginBottom:'0.5rem',color:msgSavingStatus.color}}>{msgSavingStatus.msg_info}</div>
 </div>
 <div className={styles.downLoadVideoSection_download_R}>
 <div>
 <div className={styles.downLoadVideoSection_download_R_header}> <span style={{display:"flex", alignItems:"center",margin:0,padding:0}} 
 className={styles.downLoadVideoSection_download_R_header_youtube_icon}><FaYoutube /></span>   &nbsp; 
 <span style={{display:"flex", alignItems:"center",margin:0,padding:0,lineHeight:"130%"}} 
 className={styles.downLoadVideoSection_download_R_header_text} >{langTrans.langTransData.search_result_comp[language].video_text}</span></div>
 </div>
 <div>
 <ul style={{listStyleType:"none",margin:0,padding:0}}>
 {
 typeof ytObjectState === "object" && ytObjectState.formats.filter((valid_formats)=>{ return InvalidFormats.every((word)=>{ return !valid_formats.format_id.includes(word)}) })
 .filter((valid_formats)=>{ return typeof valid_formats.format_note !== "undefined" 
 && valid_formats.format_note.split("")[valid_formats.format_note.length-1] === "p"})
 .map((format,index)=>{
 if(index < renderFormatsNum){
 return(
 <li key={index} className={styles.downLoadVideoSection_download_R_List}>
 <span className={styles.downLoadVideoSection_download_R_mediaFormat}><strong>{format.format_note}</strong> 
 <span className={styles.downLoadVideoSection_download_R_size_sm} >{format.filesize !== "undefined" && bytes()}</span> 
 <span>.{format.ext} </span> 
 <span style={{width:"fit-content",display:"flex",justifyContent:"center",alignItems:"center",paddingLeft:"0.2rem",marginTop:"0.2rem",color:"rgba(245, 46, 46, 0.8)",display:format.acodec === "none" ? "block" : "none"}}><HiMiniSpeakerXMark /></span>  </span> 
 <span className={styles.downLoadVideoSection_download_R_size} >{format.filesize !== "undefined" && bytes()}</span>
 <span className={styles.downLoadVideoSection_download_R_DownloadButtonSpan} >
 <a href="//grunoaph.net/4/7369982" target="_blank" style={{textDecoration:"none"}}>
 <button key={"chose-format-to-download" + index}  id={"choose-format-download-video" + index} aria-label={langTrans.langTransData.search_result_comp[language].download_button_text} 
 className={styles.downLoadVideoSection_download_R_DownloadButton} onClick={()=>downloadVideo(format.format_id,format.url)} >
 <span className={styles.DownloadIconn_R}><FaDownload /></span>
 <span className={styles.DownloadText_R}> {langTrans.langTransData.search_result_comp[language].download_button_text}</span>
 </button>
 </a>
 </span>
 </li>
 )
 }
 })
 }
 </ul>
 </div>
 <div style={{paddingTop:'1rem',cursor:'pointer',color:'white',display:renderFormatsNum <= 8 ? 'block' : 'none'}} onClick={()=>setRenderFormatsNum(8)}>{langTrans.langTransData.search_result_comp[language].show_more_text}</div>
 <div style={{paddingTop:'1rem',cursor:'pointer',color:'white',display:renderFormatsNum >= 10 ? 'block' : 'none'}} onClick={()=>setRenderFormatsNum(4)}>{langTrans.langTransData.search_result_comp[language].show_less_text}</div>
 </div>
 </div>
 </div>
 </center>
 </section>
 </>
 )  
 }


export default memo(YtLinksComp)