import './App.css';
import React, {Suspense,lazy,useState,useEffect} from 'react';
import TagManager from 'react-gtm-module';
import HeaderNav from './header/header.js'
import bizData from "./data/data.js"
import InputnResultComp from './pasteLinkComp/LinknResultComp.js'
import InputnResultCompHeader from './pasteLinkComp/LinknResultComp - header.js'
import styles from '././pasteLinkComp/inputNresult.module.css'
import {HelmetProvider} from "react-helmet-async";



const Footer = lazy(() => import('./footer/footer.js'));
const YTDText = lazy(() => import('./productText/productText.js'));
const MoreToolz = lazy(() => import('./moreTools/moreTools.js'));


 function App({languageType,nativeLanguage,newMessage,newMessageBody}) {
  useEffect(() => {
	TagManager.initialize({ gtmId: 'G-5WZQ9HMC2W' });
  }, []);
 return(
 
  <HelmetProvider>
  <HeaderNav ssytbizData={bizData} selectedLanguageSource={languageType}  />
  <InputnResultCompHeader language={languageType} langTrans={nativeLanguage} bizData={bizData} />
  <Suspense>
  <InputnResultComp language={languageType} langTrans={nativeLanguage} bizData={bizData} />
  <MoreToolz bizData={bizData} language={languageType}  langTrans={nativeLanguage}/>
  <YTDText bizData={bizData} language={languageType}  langTrans={nativeLanguage} newMessage={newMessage} newMessageBody={newMessageBody} />
  <Footer companyData={bizData} language={languageType}  langTrans={nativeLanguage} />
  </Suspense>

  </HelmetProvider >

 )  
 
 }


export default App;
