
import {MdClose} from 'react-icons/md'
import {HiMiniLanguage} from 'react-icons/hi2'
import { useState,useEffect,useRef,fowardRef,useCallback,useFlushsync } from 'react';
import {FaLanguage} from 'react-icons/fa6'
import styles from './Header.module.css'

import isoLangs from "../data/langs.js"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


function Header({ssytbizData,selectedLanguageSource}){
return(
<>
<header className={styles.HeaderMdLg}>
<HeaderMdLg companyInfo={ssytbizData} selectedLanguage={selectedLanguageSource}   />
</header>

<header className={styles.HeaderMobile}>
<HeaderMobile companyInfo={ssytbizData} selectedLanguage={selectedLanguageSource} />
</header>
</>
)}

export default Header;


function HeaderMdLg({companyInfo,selectedLanguage}){

const innerHeader = useRef(null)


return(
<>
<div  style={{backgroundColor:companyInfo.header_assets.header_background_color,display:selectedLanguage === "ar" ? "none" : "block"}} >
<div  className={styles.headerContainer}  >
<div  className={styles.header_Inner_container} >
<div  style={{flex:'0.405',display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',color:'white'}} className={styles.o_name}>
<Logo />
<div className={styles.company_name} style={{marginTop:"-0.1rem",lineHeight:"0",fontSize:'1.8rem',display:'flex',alignItems:'center',textTransform:"uppercase",marginLeft:"3%"}}> <Link to={"#"} style={{textDecoration:"none",color:"inherit"}}> {companyInfo.company_name} </Link></div>
</div>
<div className={styles.langComp} style={{flex:'0.595',display:'flex',alignItems:'center',paddingLeft:'0rem',height:'auto'}}>
<span style={{marginLeft:"auto"}}>  </span>
</div>
</div>
</div>
</div>

<div style={{backgroundColor:companyInfo.header_assets.header_background_color,display:selectedLanguage === "ar" ? "block" : "none"}} >
<div className={styles.headerContainer}  >
<div className={styles.header_Inner_container} >

<div className={styles.langComp} style={{flex:'0.595',display:'flex',alignItems:'center',paddingLeft:'0rem',height:'auto',marginRight:"auto"}}>
<span style={{marginRight:"auto"}}>  </span>
</div>

<div style={{flex:'0.405',display:'flex',flexDirection:'row',alignItems:'center',color:'white'}} className={styles.o_name}>
<div className={styles.company_name} style={{marginLeft:"auto",marginTop:"-0.1rem",lineHeight:"0",fontSize:'1.8rem',display:'flex',alignItems:'center',textTransform:"uppercase",marginRight:"3%"}}> <Link to={"#"} style={{textDecoration:"none",color:"inherit"}} >{companyInfo.company_name} </Link></div>
<Logo />
</div>

</div>
</div>
</div>

</>
)}




function HeaderMobile({companyInfo,selectedLanguage}){

return(
<>

<div  style={{width:"100%",height:"auto",backgroundColor:'rgb(41, 11, 135)',display:selectedLanguage === "ar" ? "none" : "block"}} >
<div  className={styles.headerInerContainer}>
<span style={{width:"9%",height:"auto"}}><Logo /></span>
<span style={{color:companyInfo.header_assets.portfolio_onwer_name_color,marginLeft:"2.5vw",lineHeight:"70%",display:"flex",justifyContent:"center",fontSize:"7vw",textTransform:"uppercase"}}  > <Link style={{textDecoration:"none",color:"inherit"}} to={"#"}>{companyInfo.company_name}</Link> </span>
<span style={{height:"30%",display:"flex",justifyContent:"center",alignItems:"center",marginLeft:"auto"}}></span>
</div>
</div>


<div style={{width:"100%",height:"auto",backgroundColor:'rgb(41, 11, 135)',display:selectedLanguage === "ar" ? "block" : "none"}} >
<div  className={styles.headerInerContainer}>
<span style={{height:"30%",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"auto"}}></span>
<span style={{color:companyInfo.header_assets.portfolio_onwer_name_color,marginRight:"2.5vw",lineHeight:"70%",display:"flex",justifyContent:"center",fontSize:"7vw",textTransform:"uppercase"}}  > <Link style={{textDecoration:"none",color:"inherit"}} to={"#"}> {companyInfo.company_name} </Link> </span>
<span style={{width:"9%",height:"auto"}}><Logo /></span>
</div>
</div>

</>
)}


function Logo(){
return (
<>

<Link className={styles.logo_frame}  to={"#"}>
<img src={'/logo-ssyt.svg'} width="100%" height="100%" alt="ssyt.tech logo" style={{verticalAlign:"bottom"}}/>
</Link>

</>
)
}

function LogoArab(){
return (
<>
<span className={styles.logo_frame_ar} >
<img src={'/logo-ssyt.svg'} width="100%" height="100%" alt="ssyt.tech logo"/>
</span>
</>
)
}



function LanguageTranslate({language}){

const [openLangList,setOpenLangList] = useState(false)

const ref = useRef(null);

const tempLangArr = []

const [langList,setLangList] = useState("")

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openLangList && ref.current && !ref.current.contains(e.target)) {
         setOpenLangList(false);
      }
      };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openLangList]);
  
  const LangMenueSwitch = ()=>{	
  !openLangList ? setOpenLangList(true) : setOpenLangList(false)
 }
 
 const closeLangComp = ()=>{
  setOpenLangList(false)	
 }



return (
<>
<div className={styles.languageTrans}  style={{display:"flex",alignItems:"center",justifyContent:"center",height:"10%"}}  ref={ref} >
<span className={styles.selectedLanguage} style={{display:"flex",alignItems:"center",justifyContent:"center",height:"10%"}}  ><FaLanguage onClick={LangMenueSwitch} style={{cursor:"pointer",padding:"0",margin:"0"}} /></span>
<div  className={language === "ar" ?  styles.languageListArab: styles.languageList}  style={{display : !openLangList ? "none" : "block"}}>
<nav>
<ul style={{listStyleType:"none",margin:0,padding:0}}>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/en"}><li style={{display:language === "en" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.en.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/es"}><li style={{display:language === "es" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.es.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ar"}><li style={{display:language === "ar" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ar.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/fr"}><li style={{display:language === "fr" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.fr.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/de"}><li style={{display:language === "de" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.de.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/hi"}><li style={{display:language === "hi" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.hi.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/id"}><li style={{display:language === "id" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.id.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/it"}><li style={{display:language === "it" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.it.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ja"}><li style={{display:language === "ja" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ja.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ko"}><li style={{display:language === "ko" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ko.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/zh"}><li style={{display:language === "zh" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.zh.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/pt"}><li style={{display:language === "pt" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.pt.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/ru"}><li style={{display:language === "ru" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.ru.nativeName}</li></Link>
    <Link style={{textDecoration:"none",color:"inherit"}} to={"/th"}><li style={{display:language === "th" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.th.nativeName}</li></Link>
	<Link style={{textDecoration:"none",color:"inherit"}} to={"/tr"}><li style={{display:language === "tr" ? "none" : "block"}}  className={styles.languageList_langs} onClick={closeLangComp}>{isoLangs.tr.nativeName}</li></Link>
</ul>
</nav>
</div>

</div>
</>
)
}




	{/*
langData.map(([key,value],index)=>

<li className={styles.languageList_langs}  key={index+value.name} onClick={()=>setOpenLangList(false)}><a href={`/${key}`}>{value.nativeName}</a></li>

)	
	*/}