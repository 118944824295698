const languageTranslations = {
    "axtryendheu": {},
    "langTransData": {
        "header": {
            "title": "影片下载",
            "discription": "最好的免费 YouTube 下载器！下载无限量的 YouTube 视频，从 YouTube 下载视频后保存 YouTube 视频链接和信息。ssyoutube是下载YouTube教程和各种重要YouTube视频的最佳YouTube下载器，因为它罕见的保存YouTube视频链接和信息功能。ssyoutube，是一款先进的YouTube下载器，具有高速下载、支持多种视频格式且非常安全。"
        },
        "search_result_comp": {
            "zh": {
                "header_text": "最好的免费 YouTube 下载器",
                "header_text_sub": "下载 YouTube 视频 | 保存 YouTube 视频链接和信息",
                "placeholder_text": "将您的 youTube 视频链接粘贴到此处",
                "download_button_text": "下载",
                "result_header_text": "下载视频为：",
                "savelink_button_text": "保存 YouTube 视频链接",
                "savelink_button_text_title": "保存 YouTube 视频 URL、标题、频道、持续时间、日期",
                "video_text": "视频",
                "show_more_text": "展示更多",
                "show_less_text": "Show less",
                "author_text": "频道 ",
                "duration_text": "持续时间",
                "input_box_title": "将您的 youTube 视频链接粘贴到此处",
                "fetching_data": "正在获取 YouTube 视频......"
            }
        },
        "saved_youtube_links_section": {
            "zh": {
                "input_placeholder_text": "查找 $ 保存的 YouTube 视频        ",
                "input_placeholder_text_title": "查找 $ 保存的 YouTube 视频",
                "select_a": "标题",
                "select_b": "作者",
                "select_c": "期间",
                "select_d": "日期",
                "select_e": "关联",
                "select_f": "描述",
                "select_g": "渠道",
                "select_value_a": "s_title",
                "select_value_b": "s_author",
                "select_value_c": "duration",
                "select_value_d": "date",
                "select_value_e": "url",
                "input_search_alert_a": "无法匹配任何 YouTube 视频",
                "input_search_alert_a_cont": "你已保存了",
                "input_search_alert_b": "成立",
                "input_search_alert_b_cont": "YouTube视频",
                "input_search_alert_b_cont_single": "Youtube 视频",
                "input_search_alert_c": "已保存 0 个 YouTube 视频信息",
                "input_search_alert_c_cont": "已保存",
                "copy_links_button_text_top": "复制",
                "copy_links_info_button_text_top": "复制",
                "copy_links_info_button_text_top_cont": "& 信息",
                "unsaved_videomsg_a": "您尚未保存任何 YouTube 视频链接",
                "unsaved_videomsg_b": "下载 YouTube 视频，然后使用“保存 YouTube 视频链接”按钮手动保存 YouTube 视频链接。",
                "unsaved_videomsg_c": "或者",
                "unsaved_videomsg_d": "打开“自动保存”，以便在每次下载后自动保存您的 Youtube 视频链接。",
                "select_button_title": "选择 YouTube 视频",
                "select_button_copy_title": "复制 YouTube 链接",
                "select_button_delete_title": "删除 YouTube 链接",
                "select_button_visit_title": "在 YouTube 上观看视频",
                "select_button_download_title": "下载 YouTube 视频",
                "auto_save_button_text": "自动保存 ",
                "auto_save_button_title_text": "下载后自动保存链接",
                "copy_youtube_links_button_text": "复制 youtube 链接",
                "copy_youtube_links_button_title_text": "提取所有已保存的 YoTtube 链接",
                "copy_youtube_links_info_button_text": "复制 YouTube 链接和信息",
                "copy_youtube_links_info_button_title_text": "提取所有已保存的 YouTube 链接和信息",
                "auto_save_button_text_switch_text_on": "在",
                "auto_save_button_text_switch_text_off": "离开",
                "author": "作者",
                "channel": "渠道",
                "duration": "期间",
                "link": "关联",
                "links": "链接",
                "input_search_alert_b_cont_info_text": "信息",
                "delete_saved_yt_links_file": "删除 YouTube 视频链接",
                "delete_saved_yt_links_file_msg": "您想删除保存的 YouTube 视频链接吗",
                "delete_saved_yt_links_yes": "是的",
                "delete_saved_yt_links_no": "不",
                "delete_saved_yt_links_statusMsg": "完毕！",
                "no_loc": "您尚未保存任何 YouTube 视频链接",
				"saved_YT_links_header":"已保存的 YouTube 链接"
            }
        },
        "back_up_manager": {
            "zh": {
                "back_up_button_text": "备份链接",
                "retrive_button_text": "检索链接",
                "retrive_button_title": "检索备份链接",
                "back_up_button_text_mb": "备份",
                "retrive_button_text_mb": "检索链接",
                "back_up_placeholder_text": "具有唯一字符串的备份链接",
                "retrive_placeholder_text": "输入您的唯一字符串",
                "back_up_input_title": "唯一的字符串将用于识别您保存的数据",
                "retrive_input_title": "您用于备份的唯一字符串",
                "back_up_status": "已成功备份",
                "retrive_status": "成功检索 YouTube 链接",
                "back_up_err": "使用另一个文件名",
                "back_up_err_no_loc": "您尚未保存 YouTube 链接",
                "back_up_retive_err": "无法完成您的请求",
                "more_text": "备份 YouTube 链接",
                "less_text": "隐藏备份 YouTube 链接",
                "more_tools_avail": "备份Youtube链接和批量下载工具可用",
                "batch_download_header_text": "批量下载Youtube视频",
                "batch_download_textarea_placeholder_text": "YouTube 链接最多 $$",
                "batch_download_button_text": "批量下载全部",
                "batch_download_button_text_720": "下载全部 - 720p",
                "batch_download_success": "全部下载成功",
                "batch_download_error": "批量下载失败",
                "batch_download_error_too_many_urls": "YouTube 网址不能超过 $",
                "invalid_yt_url": "检测到无效的 YouTube 网址",
                "Maximum_batch_download": 20,
                "batch_download_textarea_title": "一次下载多个 YouTube 视频",
                "download_terminated": "批量下载已终止",
                "download_terminated_title": "终止批量下载",
                "add_youtube_links_err": "添加 YouTube 视频链接"
            }
        },
        "product_text": {
            "zh": {
                "about_product_a": "<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n\t\t\t\tssyoutube非常适合下载YouTube教程和各种重要的YouTube视频，因为它少有的保存YouTube视频链接和信息的功能。</font><font style=\"vertical-align: inherit;\">YouTube视频下载者，包括学生和各种电子学习者，在下载YouTube视频后终于有难得的机会保存YouTube视频链接和信息。\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                ssyoutube 是最好、最可靠的 YouTube 在线下载器。</font><font style=\"vertical-align: inherit;\">有多少 YouTube 下载者在下载 YouTube 视频后可以保存 YouTube 视频链接？\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                下载 YouTube 视频并保存其链接，这样您就不会丢失已下载的重要 YouTube 视频的链接。</font><font style=\"vertical-align: inherit;\">ssyoutube 是一款免费的在线 YouTube 下载器，并将永远免费。\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                除了保存有关下载的 YouTube 视频的链接和信息外，还可以通过标题、作者、描述、日期和 URL 找到保存的 YouTube 视频链接和信息。</font><font style=\"vertical-align: inherit;\">当用户忘记视频标题、感到困惑或系统中有大量下载的视频时，为用户提供多种选项来轻松查找下载的 YouTube 视频。\n\t\t\t\t</font></font></p>\n\t\t\t\t<p></p>",
                "about_product_b": "<span translate='no'>{bizData.company_name}</span>无意与世界著名的免费 Youtube 视频下载器竞争 (ssyoutube.com)，但要称赞它的伟大之处，它具有用户迫切需要但目前无法使用的功能ssyoutube.com。 <span translate='no'>{bizData.company_name}</span>，提供某些功能，例如保存和管理下载的 Youtube 视频链接和信息，目前在ssyoutube.com。<span translate='no'>{bizData.company_name}</span>是一个很好的选择ssyoutube.com。",
                "supported_platforms_header_text": "支持的平台",
                "supported_platforms_paragraph": "支持所有平台和设备。",
                "download_yt_video_header_text": "如何下载 YouTube 视频",
                "download_yt_video_paragraph": "将 Youtube 链接粘贴到上面的 Youtube 链接输入字段中，然后通过各种下载按钮选择您喜欢的视频格式。",
                "save_yt_video_header_text": "下载后如何保存 YouTube 视频链接",
                "save_yt_video_text_a": "1. 下载视频后点击“保存Youtube视频链接”按钮。",
                "save_yt_video_text_or": "或者",
                "save_yt_video_text_b": "2. 点击自动保存按钮，每次下载后自动保存Youtube视频链接。",
                "save_yt_video_text_paragraph": "您是否曾经浪费过宝贵的时间来搜索以前在系统中下载的 Youtube 视频，或者无法找到以前下载的 Youtube 视频的链接？保存下载的 Youtube 视频链接和信息<span translate='no'>{bizData.company_name}</span>。",
                "were_yt_links_saved_header_text": "YouTube 链接存储在哪里",
                "were_yt_links_saved_paragraph": "下载的 Youtube 视频链接和信息安全地存储在您浏览器的本地存储中，并可选择备份在<span translate='no'>{bizData.company_name}</span>数据库。",
                "how_safe_header_text": "在我的浏览器上保存 Youtube 视频链接是否安全",
                "how_safe_paragraph": "大多数网络浏览器都有一个本地存储数据库，用于在浏览器中保存数据。出于安全原因，保存在浏览器本地存储中的数据在保存之前会被字符串化。保存下载的 Youtube 视频链接和信息是 100% 安全的<span translate='no'>{bizData.company_name}</span>。请使用各种网站安全工具在线验证我们的声明。",
                "how_to_backup_header_text": "如何备份 YouTube 链接和信息",
                "how_to_backup_paragraph": "保存的 Youtube 链接和信息使用一次性唯一字符串进行备份，以便在 YouTube 上进行识别和检索<span translate='no'>{bizData.company_name}</span>数据库。在输入字段中输入您的唯一字符串，然后单击“备份链接”按钮。",
                "how_to_batch_download_header": "如何批量下载 YouTube 视频",
                "how_to_batch_download_text": "将所有 YouTube 视频网址粘贴到批量下载文本区域，然后单击“批量下载全部”按钮。所有 Youtube url 链接应放置在单独的行上，并且不应以“逗号 (',')”分隔。",
                "services_free_ytd_header": "免费 YouTube 下载器",
                "services_free_ytd_header_p": "免费 YouTube 下载器",
                "services_save_ytd_header": "保存 YouTube 链接",
                "services_save_ytd_header_p": "保存和管理下载的 YouTube 视频链接。",
                "services_batch_ytd_header": "批量下载",
                "services_batch_ytd_header_p": "一次下载多个 YouTube 视频。",
                "privacy_header": "你会尊重我的隐私吗",
                "privacy_text": "我们尊重您的隐私，绝不会利用您。我们绝不会收集、存储或出售您的个人信息给第三方。当您访问或下载 YouTube 视频时，我们不会安装 cookie、跟踪器、机器人等 <span translate='no'>{bizData.company_name}</span>。",
                "product_story": "\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n我们开发 ssyoutube 是为了满足保存从 YouTube 下载的每个 YouTube 教程的链接和信息的日益增长的需求，特别是学生和依赖 YouTube 进行教育目的的各种电子学习者。</font><font style=\"vertical-align: inherit;\">ssyoutube 是我们辛勤工作和努力的结果，为 YouTube 视频下载社区提供了一个新的下载器，解决了他们当前的视频下载问题。\n</font></font></p>\n<b><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n下载 YouTube 视频后保存 YouTube 视频链接和信息的优点：\n</font></font></b>\n<ul>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n学生和各种电子学习者可以轻松地与同学和朋友分享他们过去用于教育目的或在需要时与同学和朋友分享的重要 YouTube 教程的 URL 链接。\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nYouTube 视频下载者在删除视频或格式化系统后可以轻松地重新下载 YouTube 视频，而无需浪费时间搜索他们过去下载过的各种 YouTube 视频。\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nssyoutube 保存 YouTube 链接工具使 YouTube 视频下载者可以轻松查找、管理、组织和浏览保存在其系统上的许多 YouTube 视频。\n</font></font></p>\n</li>\n</ul>\n"
            }
        },
        "save_link_status_msg": {
            "zh": {
                "success": "YouTube 视频信息已成功保存",
                "error": "错误！此 YouTube 链接之前已保存过",
                "default_msg": ""
            }
        },
        "footer": {
            "zh": {
                "services_a": "下载 YouTube 视频",
                "services_b": "保存 YouTube 视频链接和信息",
                "services_c": "一次下载多个 YouTube 视频",
                "services_by_text": "YouTube 视频下载器",
                "menu_about": "关于我们",
                "menu_privacy": "隐私政策",
                "menu_terms": "服务条款",
                "menu_contact": "接触"
            }
        }
    }
}
export default languageTranslations